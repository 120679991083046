import React, { ComponentType, useContext, useEffect, useState } from 'react'
import Context from './AppContext'
import { STYLE_OVERRIDES_ID } from '../symbols'
import { IStylesStore } from '@wix/thunderbolt-symbols'

const createCssProperty = (style: Record<string, string>) =>
	Object.entries(style).reduce((styleString, [propName, propValue]) => `${styleString}${propName}:${propValue};`, '')

const createCssRule = (compId: string, style: Record<string, string>) =>
	`#${compId}, [id^="${compId}__"]{${createCssProperty(style)}}`

const calculateCss = (stylesStore: IStylesStore) =>
	Object.entries(stylesStore.getEntireStore())
		.reduce<Array<string>>(
			(acc, [compId, style]) =>
				style && Object.keys(style).length ? [...acc, createCssRule(compId, style)] : acc,
			[]
		)
		.join(' ')

const ComponentsStylesOverrides: ComponentType = () => {
	const { styles: stylesStore } = useContext(Context)

	const [css, setCss] = useState(() => calculateCss(stylesStore))
	useEffect(() => {
		stylesStore.subscribeToChanges(() => {
			setCss(calculateCss(stylesStore))
		})
	}, [stylesStore])
	// TODO - sanitize css, e.g. background-image: url(javascript:alert('Injected'));
	return <style id={STYLE_OVERRIDES_ID} dangerouslySetInnerHTML={{ __html: css }} />
}

export default ComponentsStylesOverrides
